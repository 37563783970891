.balances-wrapper {
  &__tabs {
    margin-top: -16px;
    width: 100%;
    border-bottom: 1px solid #E9E9E9;
    .tabs_wrapper {
      width: 100%;
      margin-bottom: -1px;
    }
  }
  &__content {
    position: relative;
    &--view-history {
      position: absolute;
      right: 0;
      top: 8px;
      color: #F23EA6;
      font-family: "InterSemiBold", sans-serif;
      font-size: 14px;
    }
  }
}

.add_credits_form {
  margin-top: 60px!important;
  .custom_input_wrapper .custom_input.custom_input_error:before, .custom_input_wrapper .custom_input.custom_input_error:hover:before {
    display: none;
  }
  .custom_input_wrapper {
    &::after {
      content: "€";
      position: absolute;
      right: 1px;
      top: 1px;
      height: 38px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      border-radius: 0 3px 3px 0;
      background-color: #F2F2F2;
    }
  }
}

.adding-credits-history, .requests-history {
  display: flex;
  flex-flow: column;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 5px 7px -3px rgba(88,88,88,0.2), 0 12px 19px 1px rgba(92,92,92,0.06), 0 5px 23px 4px rgba(89,89,89,0.05);
  padding: 40px;
  &__title {
    margin: 50px 0 40px;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 29px;
    font-family: "InterSemiBold", sans-serif;
  }
  &__row {
    &--bold-text {
      color: #383838;
      font-family: "InterSemiBold", sans-serif;
      font-size: 14px;
      line-height: 18px;
      &.income {
        color: #30837B;
      }
      &.outcome {
        color: #E02020;
      }
    }
    &--light-text {
      margin-top: 2px;
      color: #9B9B9B;
      font-size: 12px;
      line-height: 16px;
    }
    &--wrapper-with-overflow {
      max-width: 95%;
      box-sizing: border-box;
    }
  }
}