.company-header {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px 0 40px;
  &__first-block {
    width: 60%;
  }
  &__last-block {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__name {
    margin-bottom: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-family: "InterSemiBold", sans-serif;
    font-size: 24px;
    line-height: 29px;
  }
  &__time-points {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 17px;
    color: #9B9B9B;
    &--value {
      color: #656565;
    }
  }
  &__balance {
    margin-right: 40px;
    text-align: right;
    &--label {
      margin-bottom: 4px;
      color: #9B9B9B;
      font-size: 12px;
      line-height: 15px;
    }
    &--value {
      font-family: "InterSemiBold", sans-serif;
      font-size: 18px;
      line-height: 21px;
    }
  }
}