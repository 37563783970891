.campaign-row {
  &__name {
    font-family: "InterSemiBold", sans-serif;
  }
  &__owner {
    &--name {
      margin-bottom: 2px;
      font-size: 14px;
      line-height: 18px;
    }
    &--email {
      color: #9B9B9B;
      font-size: 12px;
      line-height: 16px;
    }
  }
  &__admins {
    color: #F23EA6;
    font-family: "InterSemiBold", sans-serif;
    font-size: 14px;
    line-height: 18px;
    display: flex;
  }
  &__admin-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &--name {
      margin-bottom: 4px;
      font-size: 14px;
      font-family: "InterSemiBold", sans-serif;
      line-height: 18px;
    }
    &--email {
      color: #9B9B9B;
      font-size: 12px;
      line-height: 16px;
    }
    &--invited-by {
      color: #9B9B9B;
      font-size: 12px;
      line-height: 16px;
      & > span {
        color: #656565;
      }
    }
  }
}