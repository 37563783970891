.back-link {
  color: #F23EA6;
  font-family: "InterBold", sans-serif;
  letter-spacing: 1px;
  line-height: 17px;
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  transition: 0.3s linear all;
  &:hover {
    opacity: 0.7;
  }
  &__arrow {
    width: 7px;
    margin-right: 10px;
  }
}