.app-user-sharing {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  .total-block {
    margin-bottom: 32px;
  }
  &__wrapper {
    display: flex;
    column-gap: 32px;
  }
  &__block {
    padding: 32px 0 20px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px -2px rgba(88,88,88,0.3), 0 3px 13px 2px rgba(89,89,89,0.05);
    display: flex;
    align-items: center;
    flex-flow: column;
    &--title {
      color: #656565;
      font-family: "InterSemiBold", sans-serif;
      font-size: 12px;
      line-height: 15px;
      text-transform: uppercase;
    }
    &--progressbar {
      margin: 44px 0 36px;
      height: 133px;
      width: 133px;
    }
    &--users {
      &-value {
        color: #383838;
        font-family: "InterBold", sans-serif;
        font-size: 44px;
        letter-spacing: -1px;
        line-height: 54px;
      }
      &-label {
        color: #9B9B9B;
        font-family: "InterSemiBold", sans-serif;
        font-size: 14px;
        line-height: 17px;
      }
    }
    &--description {
      color: #9B9B9B;
      font-family: "InterSemiBold", sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
    }
    &--credits {
      &-block {
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px dashed #E2E5EB;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        font-size: 14px;
        line-height: 20px;
      }
      &-value {
        color: #383838;
        font-family: "InterSemiBold", sans-serif;
      }
      &-label {
        color: #9B9B9B;
      }
    }
  }
}