.admin-row {
  &__name {
    font-family: "InterSemiBold", sans-serif;
    font-size: 14px;
    line-height: 18px;
    .is-owner {
      margin-top: 2px;
      color: #6429B3;
      font-family: "InterMedium", sans-serif;
      font-size: 12px;
      line-height: 16px;
    }
  }
&__email {
  font-size: 14px;
  line-height: 18px;
}
  &__companies, &__campaigns {
    display: flex;
    color: #F23EA6;
    font-family: "InterSemiBold", sans-serif;
    font-size: 14px;
    line-height: 18px;
    width: max-content;
  }
  &__campaign-row, &__company-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &--name {
      font-size: 14px;
      font-family: "InterSemiBold", sans-serif;
      line-height: 18px;
      max-width: 300px;
    }
    &--company, &--campaign {
      margin-top: 4px;
      color: #9B9B9B;
      font-size: 12px;
      line-height: 16px;
      max-width: 300px;
    }
    &--owner {
      color: #9B9B9B;
      font-size: 12px;
      line-height: 16px;
      & > span {
        color: #656565;
      }
    }
    &--owner, &--admin {
      font-family: "InterMedium", sans-serif;
      font-size: 12px;
      line-height: 16px;
    }
    &--owner {
      color: #6429B3;
    }
    &--admin {
      color: #656565;
    }
  }
}