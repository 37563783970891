.table-container {
  width: 100%;
  .table-header {
    box-sizing: border-box;
    .table-row {
      display: flex;
      align-items: center;
      height: 44px;
      .table-cell {
        display: flex;
        align-items: center;
        user-select: none;
        cursor: pointer;
        font-size: 12px;
        color: #9B9B9B;
        &__label {
          margin-right: 4px;
        }
        &__bold-text {
          font-family: "InterSemiBold", sans-serif;
          font-size: 14px;
          line-height: 18px;
        }
        &__usual-text {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
  .table-header {
    border-bottom: 1px solid #E2E5EB;
  }
  .table-header,
  .table-body {
    width: 100%;
    .table-row {
      width: 100%;
    }
  }
  .table-body {
    .table-cell {
      box-sizing: border-box;
      overflow: hidden;
      &.actions {
        padding-right: 20px;
        overflow: unset;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        column-gap: 16px;
      }
    }
    .table-row {
      border-bottom: 1px solid #E2E5EB;
      width: 100%;
      display: flex;
      align-items: center;
      height: 68px;
      text-decoration: none;
      &.clickable {
        cursor: pointer;
      }
    }
  }
  .no-items {
    margin-top: 16px;
  }
}

.table-search {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  .select_wrapper {
    width: 228px;
  }
}

.table-date-pickers {
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.table-cell {
  &__default-text {
    color: #383838;
    font-size: 14px;
    line-height: 18px;
    &.total_campaigns_count {
      color: #6429B3;
      font-family: "InterSemiBold", sans-serif;
    }
    &.clickable {
      cursor: pointer;
      color: #F23EA6;
      font-family: "InterSemiBold", sans-serif;
    }
  }
  &__company, &__campaign {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: 98%;
    &--name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
      margin-bottom: 2px;
      font-size: 14px;
      color: #383838;
      font-family: "InterSemiBold", sans-serif;
    }
    &--legal-name {
      color: #9B9B9B;
      font-size: 12px;
      line-height: 16px;
    }
    &--status {
      font-family: "InterMedium", sans-serif;
      font-size: 12px;
      &.approved {
        color: #30837B;
      }
      &.suspended {
        color: #9B9B9B;
      }
      &.pending {
        color: #324EA3;
      }
      &.rejected {
        color: #E02020;
      }
    }
    &--created-by, &--company-of-campaign {
      color: #9B9B9B;
      font-size: 12px;
      line-height: 16px;
      span {
        color: #656565;
      }
    }
  }
  &__status {
    font-size: 14px;
    font-family: "InterSemiBold", sans-serif;
    line-height: 18px;
    &.approved, &.active {
      color: #30837B;
    }
    &.suspended, &.archived {
      color: #9B9B9B;
    }
    &.pending, &.finalized, &.finalizing {
      color: #324EA3;
    }
    &.rejected, &.stopped_immediately {
      color: #E02020;
    }
    &.inactive {
      color: #E27C12;
    }
    &.deleted {
      color: #9B9B9B;
    }
  }
  &__user {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: 98%;
    &--name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
      margin-bottom: 2px;
      font-size: 14px;
      color: #383838;
      &.bold {
        font-family: "InterSemiBold", sans-serif;
      }
    }
    &--email {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
      font-size: 12px;
      color: #9B9B9B;
    }
    &--id {
      font-size: 12px;
      color: #9B9B9B;
    }
  }
  &__platform {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    &--icon {
      margin-right: 4px;
      width: 18px;
      height: 18px;
    }
  }
  &__balance {
    font-size: 14px;
    line-height: 18px;
    &.in-bold {
      font-family: "InterSemiBold", sans-serif;
    }
  }
  &__date-time {
    font-size: 11px;
    line-height: 18px;
    &--date {
      display: flex;
      align-items: center;
    }
  }
  &__question-icon {
    margin-left: 4px;
    width: 12px;
    height: 12px;
  }
  &__is-sent {
    color: #30837B;
    font-family: "InterSemiBold", sans-serif;
    font-size: 14px;
  }
  &__pending {
    color: #FE8F1D;
    font-family: "InterSemiBold", sans-serif;
    font-size: 14px;
  }
}
