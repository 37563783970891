.company-products {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  .product-row {
    &__date {
      font-size: 14px;
      line-height: 18px;
    }
    &__type {
      max-width: 90%;
      font-size: 14px;
      line-height: 18px;
      b {
        font-weight: normal;
        font-family: "InterSemiBold", sans-serif;
      }
    }
    &__price {
      font-family: "InterSemiBold", sans-serif;
      font-size: 14px;
    }
    &__product {
      grid-template-columns: 56px 1fr;
      &--info {
        width: calc(100% - 56px);
        box-sizing: border-box;
      }
      &--image {
        width: 40px;
        height: 40px;
        & > img {
          width: 40px;
          height: 40px;
          object-fit: cover;
        }
      }
      &--name {
        font-size: 14px;
        font-family: "InterSemiBold", sans-serif;
        line-height: 18px;
        margin-bottom: 2px;
        display: flex;
        align-items: center;
      }
      &--description {
        width: max-content;
        max-width: 24vw;
        color: #9B9B9B;
        font-size: 12px;
        line-height: 16px;
      }
      &--api {
        margin-left: 8px;
      }
    }
    &__name {
      font-family: "InterMedium", sans-serif;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 2px;
    }
    &__email {
      color: #9B9B9B;
      font-size: 12px;
      line-height: 16px;
    }
    &__campaigns {
      display: flex;
      color: #F23EA6;
      font-family: "InterSemiBold", sans-serif;
      font-size: 14px;
      line-height: 18px;
      width: max-content;
    }
  }
}