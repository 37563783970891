.campaign-distribution {
  width: 600px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  row-gap: 30px;
  &__block {
    width: 100%;
  }
  &__label {
    margin-bottom: 13px;
    font-size: 12px;
    font-family: "InterSemiBold", sans-serif;
    line-height: 15px;
  }
  &__field {
    box-sizing: border-box;
    padding: 0 15px;
    width: 100%;
    border: 1px solid rgba(#CECECE, 30%);
    border-radius: 4px;
    min-height: 37px;
    display: flex;
    align-items: center;
    color: #000000;
    font-size: 12px;
    line-height: 13px;
    &.with-adornment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 0 15px;
    }
    &--adornment {
      height: 35px;
      padding: 0 15px;
      border-radius: 0 3px 3px 0;
      background-color: #F2F2F2;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__fields {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 15px;
    &.ml-20 {
      margin-left: 20px;
    }
  }
  &__deliveries {
    display: flex;
    flex-flow: column;
    row-gap: 15px;
    &--label {
      display: flex;
      align-items: center;
      font-size: 12px;
      column-gap: 5px;
      & > img {
        width: 15px;
        min-width: 15px;
        height: 15px;
      }
    }
    &--addresses {
      width: calc(100% - 20px);
      margin-left: 20px;
    }
    &--address {
      padding: 10.5px 15px;
      width: 100%;
      box-sizing: border-box;
      font-size: 12px;
      border-top: 1px solid rgba(#CECECE, 30%);
      &:last-of-type {
        border-bottom: 1px solid rgba(#CECECE, 30%);
      }
    }
  }
  &__share {
    margin-left: 20px;
    display: flex;
    flex-flow: column;
    row-gap: 15px;
    &--label {
      display: flex;
      align-items: center;
      font-size: 12px;
      column-gap: 5px;
      margin-bottom: 15px;
      & > img {
        width: 15px;
        min-width: 15px;
        height: 15px;
      }
    }
  }
}