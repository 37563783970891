.campaign-steps {
  display: flex;
  flex-flow: column;
  row-gap: 30px;
  width: 590px;
  .accordion-panel {
    border-bottom: none!important;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px -2px rgba(88,88,88,0.3), 0 3px 13px 2px rgba(89,89,89,0.05)!important;
    &__summary {
      height: 68px!important;
      width: 100%;
      box-sizing: border-box;
      padding-left: 22px!important;
    }
    &__details {
      padding: 20px 30px 40px!important;
      border-top: 2px solid #E9E9E9;
    }
  }
}

.campaign-step {
  &__summary {
    display: flex;
    align-items: center;
    &--info {
      margin-left: 16px;
      display: flex;
      flex-flow: column;
    }
    &--name {
      color: #9B9B9B;
      font-size: 11px;
      font-family: "InterSemiBold", sans-serif;
      text-transform: uppercase;
      line-height: 12px;
      margin-bottom: 2px;
    }
    &--title {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: #000000;
      font-size: 16px;
      font-family: "InterSemiBold", sans-serif;
      line-height: 17px;
    }
    &--icon {
      height: 24px;
      width: 24px;
    }
  }
  &__details {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    row-gap: 30px;
    &--label {
      margin-bottom: 8px;
      font-size: 12px;
      font-family: "InterSemiBold", sans-serif;
      line-height: 13px;
    }
    &--field {
      padding: 11px 15px;
      box-sizing: border-box;
      width: 100%;
      border: 1px solid rgba(206, 206, 206, 0.3);
      border-radius: 4px;
      color: #000000;
      font-size: 12px;
      line-height: 16px;
      &.with-unit-block {
        box-sizing: border-box;
        height: 40px;
        padding: 0 0 0 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &.with-adornment {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &.seconds {
        max-width: 124px;
      }
      &.money, &.products {
        max-width: 154px;
      }
    }
    &--unit-block {
      min-width: 49px;
      height: 100%;
      box-sizing: border-box;
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 3px 3px 0;
      background-color: #F2F2F2;
    }
    &--copy {
      margin-left: 10px;
      cursor: pointer;
    }
    &--bold-value {
      margin-top: 15px;
      color: #000000;
      font-family: "InterSemiBold", sans-serif;
      font-size: 16px;
      line-height: 24px;
    }
    &--location-fields {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &--address {
      width: calc(100% - 144px);
    }
    &--radius {
      width: 124px;
    }
    &--image-fields {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    &--qr-code {
      height: 100px;
      width: 100px;
      border: 1px solid rgba(206, 206, 206, 0.3);
      border-radius: 4px;
      & > img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }
    &--code-fields, &--discount-fields, &--sizes {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 30px;
    }
    &--sizes-wrapper {
      display: flex;
      flex-flow: column;
      row-gap: 12px;
    }
    &--bonus-fields {
      padding-top: 30px;
      border-top: 1px dashed #E2E5EB;
      width: 100%;
      display: flex;
      align-items: center;
      column-gap: 60px;
    }
    &--total {
      margin-top: 15px;
      display: flex;
      align-items: center;
      &-value {
        margin: 0 4px 4px 0;
        color: #000000;
        font-family: "InterSemiBold", sans-serif;
        font-size: 16px;
        line-height: 24px;
      }
      &-name {
        margin-right: 30px;
        color: #656565;
        font-size: 12px;
        line-height: 16px;
      }
      &-rating {
        margin-right: 30px;
        display: flex;
        align-items: center;
        color: #000000;
        font-family: "InterSemiBold", sans-serif;
        font-size: 16px;
        line-height: 24px;
        & > img {
          margin-left: 6px;
          width: 10px;
          height: 10px;
        }
      }
    }
    &--download {
      color: #F23EA6;
      font-family: "InterSemiBold", sans-serif;
      font-size: 10px;
      line-height: 16px;
      display: flex;
      align-items: center;
      &-icon {
        margin-right: 4px;
        width: 12px;
        height: 12px;
      }
    }
    &--type {
      margin-top: 17px;
      display: flex;
      align-items: center;
      color: #000000;
      font-size: 12px;
      line-height: 16px;
      & > img {
        margin-right: 8px;
        height: 20px;
        width: 20px;
      }
    }
    &--multiple {
      display: flex;
      flex-flow: column;
      width: 100%;
      margin-top: 4px;
      .most-popular {
        color: #000000;
        font-family: "InterMedium", sans-serif;
      }
      &-row {
        margin-top: 12px;
        display: flex;
        align-items: center;
        width: 100%;
        color: #656565;
        font-size: 12px;
        line-height: 13px;
        &.is-selected {
          color: #000000;
          font-family: "InterMedium", sans-serif;
        }
        .selected-icon {
          width: 20px;
          min-width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }
      &-image {
        width: 24px;
        height: 24px;
        border-radius: 2px;
        object-fit: cover;
        &-wrapper {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          position: relative;
          &:after {
            z-index: 10;
            position: absolute;
            background: url("../../../assets/image/image-hover.svg") no-repeat;
            content: '';
            top: 0;
            left: 0;
            height: 24px;
            width: 24px;
            display: none;
          }
          &:hover {
            &:after {
              display: unset;
            }
          }
        }
      }
      &-check {
        margin-right: 4px;
        width: 8px;
        height: 8px;
      }
      &-text {
        width: max-content;
        max-width: calc(90% - 32px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 15px;
        &.with-image {
          max-width: calc(90% - 64px);
        }
      }
      &-separator {
        flex: 1;
        background: transparent;
        height: 8px;
        border-bottom: 1px dashed #CECECE;
        margin: 0 4px;
      }
      &-count {
        font-family: "InterSemiBold", sans-serif;
      }
    }
    &--bars {
      margin-top: 4px;
      display: flex;
      flex-flow: column;
      width: 265px;
      &-row {
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &.bigger {
          .campaign-step__details--bars-value,
          .campaign-step__details--bars-label {
            color: #000000 !important;
            font-family: "InterSemiBold", sans-serif !important;
          }
        }
      }
      &-label {
        color: #656565;
        font-size: 12px;
        line-height: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 22px;
      }
      &-icon {
        margin: 0 0 1px 4px;
        width: 10px;
        height: 10px;
      }
      &-bar {
        height: 4px;
        width: 160px;
        border-radius: 3px;
        background-color: rgba(100,41,179,0.1);
        position: relative;
      }
      &-filled {
        position: absolute;
        left: 0;
        top: 0;
        height: 4px;
        border-radius: 3px;
        background-color: #6429B3;
      }
      &-value {
        color: #656565;
        font-family: "InterSemiBold", sans-serif;
        font-size: 12px;
        line-height: 13px;
        width: 22px;
        text-align: right;
      }
      &-name {
        width: 40px;
        display: flex;
        align-items: center;
      }
    }
    &--product {
      width: 100%;
      padding: 11px 15px;
      box-sizing: border-box;
      border: 1px solid rgba(206, 206, 206, 0.3);
      border-radius: 4px;
      display: flex;
      align-items: center;
      &-image {
        margin-right: 14px;
        height: 50px;
        width: 50px;
        border: 1px solid #E9E9E9;
        border-radius: 4px;
      }
      &-name {
        margin-bottom: 2px;
        color: #000000;
        font-family: "InterSemiBold", sans-serif;
        font-size: 12px;
        line-height: 16px;
      }
      &-description {
        color: #656565;
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
}
