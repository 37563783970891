.campaign-audience {
  width: 600px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  row-gap: 30px;
  &__block {
    width: 100%;
  }
  &__label {
    margin-bottom: 13px;
    font-size: 12px;
    font-family: "InterSemiBold", sans-serif;
    line-height: 15px;
  }
  &__field {
    padding: 0 15px;
    width: 100%;
    border: 1px solid rgba(#CECECE, 30%);
    border-radius: 4px;
    min-height: 37px;
    display: flex;
    align-items: center;
    color: #000000;
    font-size: 12px;
    line-height: 13px;
    &.age {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > span {
        color: #9B9B9B;
        font-size: 12px;
        line-height: 13px;
      }
    }
    &.tags {
      padding: 7px 15px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 10px;
      column-gap: 10px;
    }
    &--gender {
      display: flex;
      align-items: center;
      text-transform: capitalize;
      &.inactive {
        color: #9B9B9B;
      }
      & > img {
        margin-right: 5px;
        height: 15px;
        width: 15px;
      }
      .comma {
        margin-right: 10px;
      }
    }
  }
  &__age-and-gender {
    display: grid;
    grid-template-columns: 295px 255px;
    grid-column-gap: 40px;
  }
  &__fields {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }
  &__tag {
    padding: 7px 16px;
    border-radius: 16px;
    background-color: #FEF5FA;
    color: #F23EA6;
    font-family: "InterSemiBold", sans-serif;
    font-size: 14px;
    line-height: 18px;
  }
}