.contacts-edit {
  display: flex;
  width: 32px;
  height: 32px;
}

.contact-dialog {
  width: 440px!important;
  .fields-wrapper {
    width: 360px;
    margin: 0 auto;
  }
  &__two-fields {
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 20px;
    & > div {
      width: 100%;
    }
  }
  &__buttons {
    padding-top: 40px;
    border-top: 1px solid #E2E5EB;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
  }
}