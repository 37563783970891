.switch_wrapper {
  display: flex;
  align-items: center;
  & > span {
    font-family: "InterSemiBold", sans-serif;
    margin-right: 10px;
    font-size: 12px;
    color: #383838;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
  }
  & > div {
    min-width: 40px;
  }
}