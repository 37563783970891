.app-user-transactions {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  &__totals {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    column-gap: 32px;
  }
  .app-user-transaction-row {
    &__id {
      color: #383838;
      font-family: "InterSemiBold", sans-serif;
      font-size: 14px;
      line-height: 18px;
    }
    &__amount {
      font-family: "InterSemiBold", sans-serif;
      font-size: 14px;
      line-height: 18px;
      &.income {
        color: #30837B;
      }
      &.outcome {
        color: #E02020;
      }
    }
    &__details {
      padding: 32px;
      width: 100%;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: #F8F8F8;
      color: #383838;
      display: flex;
      flex-flow: column;
      row-gap: 24px;
      &--in-line {
        column-gap: 108px;
        display: flex;
        align-items: center;
      }
      &--label {
        margin-bottom: 4px;
        color: #383838;
        font-size: 12px;
        line-height: 15px;
      }
      &--value {
        font-family: "InterSemiBold", sans-serif;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        align-items: center;
      }
    }
  }
}