.auth_container {
  width: 100%;
  min-height: 720px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  form {
    width: 400px;
    position: relative;
  }
  .triangle {
    z-index: 1;
    right: 0;
    top: 0;
    position: absolute;
    width: 85vw;
    height: 100vh;
    min-height: 720px;
    background: linear-gradient(to top right, transparent 0%, transparent 50%, #f4f4f4 50%, #f4f4f4 100%);
  }
}

.login_form_wrapper {
  z-index: 2;
  padding: 80px 100px;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 5px 7px -3px rgba(88,88,88,0.2), 0 12px 19px 1px rgba(92,92,92,0.06), 0 5px 23px 4px rgba(89,89,89,0.05);
}

.auth_block_head{
  text-align: center;
  font-family: "InterBold",sans-serif;
  font-size: 30px;
  letter-spacing: -0.5px;
  line-height: 42px;
  padding-bottom: 15px;
}

.auth_btn_wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  .default_button_wrapper{
    margin-right: 20px;
  }
  .MuiSvgIcon-root{
    cursor: pointer;
    >path:last-of-type{
      fill: red
    }
  }
}

.auth_block_descriptions{
  text-align: center;
  font-family: "InterRegular",sans-serif;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 56px;
}

.auth_content {
  background: #f8f8f8;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 720px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  .campy_logo {
    z-index: 2;
    margin-bottom: 80px;
  }
}

