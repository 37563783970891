.app-user-basic-info {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  row-gap: 30px;
  &__fields {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }
  &__label {
    margin-bottom: 13px;
    color: #383838;
    font-family: "InterSemiBold", sans-serif;
    font-size: 12px;
    line-height: 15px;
  }
  &__value {
    border: 1px solid rgba(206, 206, 206, 0.3);
    border-radius: 4px;
    padding: 11px 15px;
    width: 140px;
    box-sizing: border-box;
    display: flex;
    color: #000000;
    font-size: 12px;
    line-height: 15px;
    text-transform: capitalize;
  }
  &__tags {
    border: 1px solid rgba(206, 206, 206, 0.3);
    border-radius: 4px;
    padding: 11px;
    max-width: 100%;
    box-sizing: border-box;
    display: flex;
    row-gap: 10px;
    column-gap: 10px;
    flex-wrap: wrap;
    &--tag {
      padding: 7px 16px;
      border-radius: 16px;
      background-color: #FEF5FA;
      color: #F23EA6;
      font-family: "InterSemiBold", sans-serif;
      font-size: 14px;
      line-height: 18px;
    }
  }
}