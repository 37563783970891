.statistics_wrapper {
  display: flex;
  flex-flow: column;
  .statistics_tabs_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 16px 0 32px;
    border-bottom: 1px solid #E9E9E9;
    .tabs_wrapper {
      width: 100%;
      margin-bottom: -1px;
    }
  }
  .companies_in_total_wrapper {
    display: flex;
    column-gap: 32px;
  }
  .statistics_users_wrapper {
    .loader_wrapper {
      height: 400px;
    }
    .chart_wrapper {
      margin-top: 40px;
      width: 100%;
      & > div {
        overflow: visible!important;
      }
    }
  }
}

.campaign-name {
  font-size: 14px;
  font-family: "InterSemiBold", sans-serif;
  line-height: 18px;
}