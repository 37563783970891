.campaign-header {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px 0 40px;
  &__first-block {
    width: 80%;
  }
  &__last-block {
    width: 20%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  &__name {
    margin-bottom: 8px;
    font-family: "InterSemiBold", sans-serif;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    column-gap: 16px;
  }
  &__info {
    color: #9B9B9B;
    font-size: 14px;
    line-height: 17px;
  }
  &__users-count {
    display: flex;
    align-items: center;
    font-family: "InterSemiBold", sans-serif;
    font-size: 18px;
    color: #9B9B9B;
    &--icon {
      margin-right: 8px;
      width: 20px;
      height: 20px;
    }
    &--number {
      color: #324EA3;
    }
  }
  &__status-badge {
    padding: 2px 10px;
    border-radius: 2px;
    font-size: 10px;
    line-height: 12px;
    font-family: "InterMedium", sans-serif;
    text-transform: uppercase;
    &.active {
      background-color: rgba(35, 155, 95, 0.1);
      color: #239B5F;
    }
    &.archived {
      background-color: rgba(155, 155, 155, 0.1);
      color: #9B9B9B;
    }
    &.finalized, &.finalizing {
      background-color: rgba(50, 78, 163, 0.1);
      color: #324EA3;
    }
    &.stopped_immediately {
      background-color: rgba(224, 32, 32, 0.1);
      color: #E02020;
    }
    &.inactive {
      background-color: rgba(226, 124, 18, 0.1);
      color: #E27C12;
    }
  }
}