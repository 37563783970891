.app-user-rewards {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  .total-block {
    margin-bottom: 40px;
  }
  .app-user-reward-row {
    &__title-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
    }
    &__title {
      max-width: 95%;
      color: #383838;
      font-family: "InterSemiBold", sans-serif;
      font-size: 14px;
      line-height: 18px;
    }
    &__delivery {
      color: #383838;
      font-family: "InterMedium", sans-serif;
      font-size: 14px;
      line-height: 18px;
      &.no-delivery {
        color: #9B9B9B;
      }
    }
    &__status {
      font-family: "InterMedium", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: #6429B3;
      &.paid {
        color: #30837B;
      }
      &.pending {
        color: #FE8F1D;
      }
    }
    &__details {
      padding: 32px;
      width: 100%;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: #F8F8F8;
      color: #383838;
      &--separator {
        margin: 32px 0;
        height: 1px;
        width: 100%;
        background-color: #E2E5EB;
      }
      &--product-info {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 64px;
        row-gap: 24px;
        &.grid-3 {
          grid-template-columns: 3fr 2fr 2fr;
        }
      }
      &--delivery-info {
        display: flex;
        flex-flow: column;
        row-gap: 24px;
      }
      &--in-line {
        display: flex;
        align-items: center;
        column-gap: 128px;
      }
      &--label {
        margin-bottom: 4px;
        color: #383838;
        font-size: 12px;
        line-height: 15px;
      }
      &--value {
        font-family: "InterSemiBold", sans-serif;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        align-items: center;
      }
      &--original-price {
        margin-left: 8px;
        color: #9B9B9B;
        font-size: 14px;
        line-height: 17px;
        text-decoration: line-through;
        font-family: "InterRegular", sans-serif;
      }
    }
  }
}