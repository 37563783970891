.tabs_wrapper {
  align-items: flex-end;
  height: 60px;
  & > div {
    height: 100%;
    & > span {
      background-color: #F23EA6;
    }
    .tabs_buttons_container {
      height: 100%;
      button {
        min-height: unset!important;
        min-width: 52px!important;
      }
    }
  }
  .MuiTab-wrapper {
    display: flex;
    align-items: center;
    flex-flow: row;
    img {
      width: 20px;
      margin-bottom: 0!important;
      margin-right: 8px;
    }
  }
}
.tab_wrapper {
  min-width: unset!important;
  span {
    font-size: 14px;
    font-family: "InterSemiBold", sans-serif;
    color: #9B9B9B;
    text-transform: none;
  }
  &.tab_selected {
    span {
      color: #F23EA6;
    }
  }
}