.campaign-basic-info {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  &__fields {
    width: 590px;
    margin-top: 28px;
    &--author-name {
      width: 300px;
    }
    .custom_input_label {
      color: #383838!important;
      font-family: "InterSemiBold", sans-serif;
      font-size: 12px;
      line-height: 15px;
    }
    .custom_input_disabled {
      color: #383838!important;
      .custom_input_outline {
        border-color: rgba(206, 206, 206, 0.3)!important;
      }
    }
    .custom_input {
      font-size: 12px;
    }
  }
  &__separator {
    height: 1px;
    width: 100%;
    background-color: #E2E5EB;
    margin-bottom: 60px;
  }
}