.custom_input_wrapper {
  width: 100%;
  height: 40px;
  &.multiline {
    min-height: 54px;
    height: max-content;
    .custom_input {
      font-size: 12px;
    }
    .note-multiline {
      height: 82px;
      align-items: flex-start;
    }
  }
  &:hover{
    .custom_input_outline{
      border-color: #1C2225;
    }
  }
  .custom_input_label {
    font-size: 16px;
    font-family: "InterMedium", sans-serif;
    transform: translate(0px, -25px);
    color: #111111;
    &.custom_input_label_focused {
      color: #111111;
      transform: translate(0px, -25px);
    }
    &.custom_input_label_active {
      color: #111111;
      transform: translate(0px, -25px);
    }

  }
  .custom_input {
    background-color: #FFFFFF;
    font-size: 14px;
    border-radius: 3px;
    &:focus {
      background-color: #FFFFFF;
    }
    input {
      height: 40px;
      padding: 0 40px 0 15px;
    }
    .custom_input_outline {
      border-color: rgba(55, 63, 81, 0.3);
      legend {
        width: 0!important;
      }
    }
    &.custom_input_active{
      border-color: red!important;
    }
    &.custom_input_focused {
      background-color: #FFFFFF;
      .custom_input_outline {
        border: 1px solid #1C2225;
      }
    }
    &.custom_input_error, &.custom_input_error:hover {
      position: relative;
      &:before{
        top: calc(50% - 8px);
        right: 10px;
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        background: url("../../../assets/image/c-warning-e.svg") no-repeat;
      }
      .custom_input_outline {
        border-color: #FF1919!important;
      }
    }
    &.with-percent-adornment, &.with-euro-adornment {
      width: 125px;
      position: relative;
      input {
        height: 40px;
        padding: 0 70px 0 15px;
      }
      &:before {
        position: absolute;
        right: 0;
        content: "%";
        height: 38px;
        width: 35px;
        border-radius: 0 3px 3px 0;
        background-color: #F2F2F2;
        color: #383838;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &.with-percent-adornment {
      &:before {
        content: "%";
      }
    }
    &.with-euro-adornment {
      &:before {
        content: "€";
      }
    }
  }
}

.error_text{
  min-height: 40px;
  color: #FF1919;
  bottom: -20px;
  left: 0;
  font-size: 12px;
  display: block;
  margin: 5px 0;
}