.app-user-campaigns {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  .total-block {
    margin-bottom: 40px;
  }
  .app-user-campaign-row {
    &__title-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
    }
    &__title {
      max-width: 95%;
      color: #383838;
      font-family: "InterSemiBold", sans-serif;
      font-size: 14px;
      line-height: 18px;
    }
    &__status {
      font-family: "InterMedium", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: #30837B;
      &.ongoing {
        color: #30837B;
      }
      &.finished, &.completed {
        color: #324EA3;
      }
      &.cancelled, &.canceled {
        color: #9B9B9B;
      }
      &.failed {
        color: #9B9B9B;
      }
    }
  }
}