.company-basic-info {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  &__fields {
    width: 590px;
    margin-top: 28px;
    .custom_input_label {
      color: #383838!important;
      font-family: "InterSemiBold", sans-serif;
      font-size: 12px;
      line-height: 15px;
    }
    .custom_input_disabled {
      .custom_input_outline {
        border-color: rgba(206, 206, 206, 0.3)!important;
      }
    }
    .custom_input {
      font-size: 12px;
      color: #383838!important;
    }
  }
  &__two-fields {
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 30px;
    & > div {
      width: 100%;
    }
  }
  &__address-fields {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 125px;
    column-gap: 30px;
    & > div {
      width: 100%;
    }
  }
  &__fees {
    display: flex;
    align-items: center;
    column-gap: 30px;
  }
  &__separator {
    height: 1px;
    width: 100%;
    background-color: #E2E5EB;
    margin-bottom: 60px;
  }
  &__credit-limit {
    width: 125px;
    position: relative;
    &.disabled {
      label {
        opacity: 0.3;
      }
    }
  }
  .check_field_wrapper {
    margin: -16px 0 44px;
  }
}