/* autoprefixer: off */
body {
  min-width: 1350px;
  overflow: auto;
  box-sizing: border-box;
  background: #f8f8f8;
  color: #111111;
  font-family: "InterRegular",sans-serif;
  &::-webkit-scrollbar {
    width: 17px;
    height: 17px;
  }
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;
    background: #ffffff;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: rgba(132, 148, 185, 0.3);
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(132, 148, 185, 0.3);
  }
}

.page_all{
  display: flex;
  flex-flow: column;
}

.page{
  display: flex;
  position: relative;
  z-index: 2;
  .MuiLinearProgress-root {
    position: absolute;
    width: 100vw;
    z-index: 1000;
  }
  .triangle {
    z-index: 1;
    right: 0;
    top: 0;
    position: absolute;
    width: 65vw;
    height: calc(100vh - 60px);
    background: linear-gradient(to top right, transparent 0%, transparent 50%, #f4f4f4 50%, #f4f4f4 100%);
  }
}

.page-wrapper{
  display: flex;
  flex-flow: column;
  row-gap: 32px;
  width: calc(100% - 320px);
  background: transparent;
  padding: 50px 64px;
  box-sizing: border-box;
  z-index: 2;
  &__content {
    padding: 32px 40px 40px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px -2px rgba(88,88,88,0.3), 0 3px 13px 2px rgba(89,89,89,0.05);
    h2 {
      margin: 32px 0;
      font-size: 20px;
      line-height: 24px;
    }
  }
}

a, button{
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
  text-decoration: none;
  background: transparent;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.text-hover {
  cursor: pointer;
  -webkit-transition: all ease .2s;
  -moz-transition: all ease .2s;
  -o-transition: all ease .2s;
  transition: all ease .2s;
  &:hover{
    opacity: 0.5;
  }
}

.loader_wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.card_hover {
  cursor: pointer;
  -webkit-transition: all ease .2s;
  -moz-transition: all ease .2s;
  -o-transition: all ease .2s;
  transition: all ease .2s;
  &:hover{
    transform: scale(1.01);
  }
}

.no_select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.table_container{
  .table_row {
    border-bottom: 1px solid #E2E5EB;
  }
  .table_header{
    .row_item{
      font-size: 12px;
      color: #9B9B9B;
    }
    .table_row{
      width: 100%;
      display: flex;
      align-items: center;
      height: 44px;
    }
  }
  .table_body{
    .row_item{
      font-size: 14px;
    }
    .table_row{
      width: 100%;
      display: flex;
      align-items: center;
      height: 55px;
      text-decoration: none;
    }
    .no_items {
      height: 33px;
      display: flex;
      align-items: center;
    }
  }
}

.overflowed_p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.inactive_hover {
  cursor: not-allowed;
}

.margin_top_30 {
  margin-top: 30px;
}

.step_wrapper {
  position: relative;
  padding-top: 0;
  .head_block_step {
    width: 100%;
    border-bottom: none;
    padding: 0;
  }
}

.centered_loader {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step_input_wrapper {
  width: 100%;
  position: relative;
  img {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  &.seconds_block {
    img {
      right: -20px;
    }
  }
  &.money_block {
    img {
      right: -20px;
    }
  }
}

.input_title_block_my_campaign{
  & > div {
    & > img {
      cursor: help;
    }
  }
}

.tooltip_name {
  font-family: "InterSemiBold", sans-serif;
  font-size: 12px;
  span {
    font-family: "InterLight", sans-serif;
    font-size: 10px;
  }
}

.input_title_campaign {
  display: flex;
  align-content: center;
  padding-bottom: 7px;
  & > img {
    cursor: help;
  }
  span{
    color: #383838;
    font-family: "InterSemiBold",sans-serif;
    font-size: 12px;
    line-height: 15px;
    margin-right: 5px;
  }
  p {
    color: #383838;
    font-size: 18px;
    letter-spacing: -0.2px;
    line-height: 21px;
    margin-right: 5px;
  }
}

.item_radio{
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 30px;
  &:first-child{
    margin-bottom: 10px;
  }
  & > div{
    display: flex;
    align-items: center;
    margin-right: 40px;
    & > input{
      display: none;
      & + label{
        position: relative;
        cursor: pointer;
        padding-left: 30px;
        &:before, &:after{
          content: "";
          position: absolute;
          border-radius: 50%;;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          transition: all 0.3s ease;
        }
        &:before{
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          background: url("../assets/image/RadioOff.png") no-repeat;
        }
      }
      &:checked + label:before{
        background: url("../assets/image/RadioOn.png") no-repeat;

      }
    }
  }
  label{
    color: #383838;
    font-family: "InterRegular",sans-serif;
    font-size: 12px;
    line-height: 20px;
  }
}

.item_checkbox{
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  position: relative;
  & > div{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    cursor: pointer;
    input[type=checkbox] {
      -moz-appearance: initial!important;
      position: relative;
      cursor: pointer;
      padding-left: 5px;
      padding-right: 5px;
      margin-top: 5px;
      -webkit-appearance: button;
      height: 0;
      width: 19px;
      border-radius: 2px;
    }
    input[type=checkbox]:checked:after {
      content: "";
      position: absolute;
      left: 0;
      top: -11px;
      background: url("../assets/image/checkbox_checked.svg") no-repeat;
      width: 19px;
      height: 19px;
    }
    input[type=checkbox]:after {
      content: "";
      position: absolute;
      left: -0px;
      top: -11px;
      background: url("../assets/image/checkbox_unchecked.svg") no-repeat;
      width: 19px;
      height: 19px;
    }
  }
  label{
    cursor: pointer;
    color: #383838;
    font-family: "InterRegular",sans-serif;
    font-size: 12px;
    line-height: 20px;
    margin-left: 12px;
  }
}

.cursor_not_allowed {
  cursor: not-allowed!important;
  input {
    cursor: not-allowed!important;
  }
}

.default_dialog_wrapper {
  width: 380px;
  display: flex;
  flex-flow: column;
  align-items: center;
  .dialog_title {
    text-transform: capitalize;
    min-height: 39px;
    color: #F23EA6;
    font-family: "InterSemiBold", sans-serif;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 39px;
    text-align: center;
    &.not_capitalize {
      text-transform: none;
    }
  }
  .dialog_description {
    margin-top: 24px;
    color: #383838;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    &.balances_description {
      margin-top: 16px;
      color: #656565;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .buttons_wrapper {
    margin-top: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dialog_form {
    width: 160px;
    margin-top: 48px;
    label {
      font-size: 12px;
      font-family: "InterSemiBold", sans-serif;
    }
    .buttons_wrapper {
      margin-top: 0;
      .default_button_wrapper {
        &:first-of-type {
          margin-right: 20px;
        }
      }
    }
  }
}

.not_found {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 2;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.Toastify__toast--error {
  background: #E02020!important;
  padding: 6px 16px!important;
  font-size: 0.875rem!important;
  font-family: "InterSemiBold", sans-serif!important;
  height: 48px!important;
  box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)!important;
  border-radius: 4px!important;
}

.Toastify__toast--success {
  padding: 6px 16px!important;
  font-size: 0.875rem!important;
  font-family: "InterSemiBold", sans-serif!important;
  height: 48px!important;
  box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)!important;
  border-radius: 4px!important;
}

.Toastify__toast-container {
  width: 450px!important;
}

h1 {
  color: #383838;
  font-family: "InterSemiBold", sans-serif;
  font-size: 24px;
  line-height: 29px;
}

.pagination_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.search{
  position: relative;
  margin-right: 20px;
  input{
    box-sizing: border-box;
    height: 37px;
    width: 288px;
    border: 1px solid #CECECE;
    border-radius: 4px;
    background-color: #FFFFFF;
    padding: 0 20px 0 39px;
    font-family:"InterRegular",sans-serif;
    color: #111111;
    transition: all 0.3s linear;
    &::placeholder{
      color: #9B9B9B;
      font-size: 12px;
    }
    &:focus{
      border-color:#111111 ;
    }
  }
  button, span{
    display: flex;
    align-items: center;
    background: transparent;
    justify-content: center;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 15px;
    top: calc(50% - 8px);
    img {
      transition: all 0.3s linear;
    }
  }
}

.no-items {
  color: #9B9B9B;
  font-family: "InterSemiBold", sans-serif;
  font-size: 14px;
  line-height: 18px;
}

.list-dialog {
  padding: 48px 40px 0!important;
  &__wrapper {
    width: 440px;
    display: flex;
    flex-flow: column;
  }
  &__title {
    font-family: "InterSemiBold", sans-serif;
    font-size: 24px;
    line-height: 29px;
  }
  &__description {
    color: #9B9B9B;
    font-size: 14px;
    line-height: 18px;
    & > span {
      color: #656565;
    }
  }
  &__list {
    display: flex;
    flex-flow: column;
    max-height: 400px;
    min-height: 200px;
    margin-top: 32px;
    overflow: auto;
    padding-right: 18px;
    & > div {
      padding: 16px 0;
      border-bottom: 1px solid #E2E5EB;
      &:last-of-type {
        border-bottom: none;
      }
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      -webkit-border-radius: 3.5px;
      background: #ffffff;
    }
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 3.5px;
      border-radius: 3.5px;
      background-color: rgba(56,56,56,0.5);
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background-color: rgba(56,56,56,0.2);
    }
  }
}

.accordion-panel {
  box-shadow: none!important;
  border-bottom: 1px solid #E2E5EB!important;
  &:before {
    display: none;
  }
  &__summary {
    padding: 0 16px 0 0!important;
    min-height: unset!important;
    & > div {
      margin: 0!important;
    }
  }
  &__expanded {
    margin: 0!important;
    min-height: unset!important;
    box-shadow: none!important;
  }
  &__disabled {
    background-color: transparent!important;
    .accordion-panel__summary {
      opacity: 1!important;
    }
  }
  &__content {
    margin: 0!important;
  }
  &__details {
    padding: 0 0 16px!important;
  }
}

.text-capitalized {
  text-transform: capitalize;
}

.clickable-image {
  max-width: 220px;
  display: flex;
  align-items: center;
  color: #000000;
  font-size: 12px;
  line-height: 16px;
  &--wrapper {
    width: 40px;
    height: 40px;
    margin-right: 8px;
    position: relative;
    &:after {
      z-index: 10;
      position: absolute;
      background: url("../assets/image/image-hover.svg") no-repeat;
      background-size: cover;
      content: '';
      top: 0;
      left: 0;
      height: 40px;
      width: 40px;
      display: none;
    }
    &:hover {
      &:after {
        display: unset;
      }
    }
  }
  & > img {
    margin-right: 10px;
    height: 40px;
    width: 40px;
    border-radius: 2px;
    object-fit: cover;
  }
}