.check_field_wrapper {
  color: transparent;
  display: flex;
  label {
    margin: 0;
    img {
      width: 18px;
      height: 18px;
    }
    & > :first-child > :last-child > :first-child {
      width: 18px !important;
      height: 18px !important;
      top: 1px !important;
      left: 1px !important;
    }
    & > :last-child {
      color: #334150;
      font-size: 12px;
      font-family: "InterSemiBold", sans-serif!important;
      letter-spacing: 0;
      line-height: 15px;
      margin-left: 12px;
    }
  }
  .custom_check{
    padding: 0;
  }
}