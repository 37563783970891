.default_dialog_root {
  .paper_custom_dialog{
    border-radius: 6px;
    min-width: unset;
    width: unset;
    max-width: unset;
    box-shadow: none;
  }
  .dialog_close_button {
    cursor: pointer;
    position: absolute;
    right: 23px;
    top: 23px;
    background: transparent;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover{
      background: transparent;
      transform: rotate(-180deg);
    }
  }
}

.dialog_wrapper {
  padding: 50px 70px 60px;
  display: flex;
  position: relative;
  background-color: #FFFFFF;
  max-height: 850px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height:5px;
  }
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;

    background: #EDF2F8;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: grey;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: grey;
  }
  &.pickup_address {
    min-height: 480px;
    padding: 40px 40px 48px;
  }
}

.image_root_dialog {
  background-color: transparent!important;
  .image_dialog {
    padding: 26px 70px;
    max-height: 700px;
    background-color: transparent;
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 100px;
      min-height: 50px;
      img {
        max-height: 650px;
        max-width: 1000px;
      }
    }
    &::-webkit-scrollbar {
      width: 5px;
      height:5px;
    }
    &::-webkit-scrollbar-track {
      -webkit-border-radius: 2px;

      background: #EDF2F8;
    }
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 5px;
      border-radius: 5px;
      background: grey;
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background: grey;
    }
  }
}