.billing-by-company {
  &__company-name {
    margin: 50px 0 8px;
    font-size: 24px;
    font-family: "InterSemiBold", sans-serif;
    letter-spacing: 0;
    line-height: 29px;
  }
  &__company-billing-info {
    display: flex;
    margin-bottom: 40px;
    color: #9B9B9B;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
  }
  &__table-wrapper {
    position: relative;
  }
  &__export-button {
    position: absolute;
    right: 0;
    top: 0;
    .default_button.confirm {
      height: 35px!important;
    }
    .MuiButton-label {
      font-size: 12px;
      font-family: "InterBold", sans-serif;
      line-height: 15px;
    }
  }
  &__row {
    &--name {
      font-size: 14px;
      font-family: "InterSemiBold", sans-serif;
      line-height: 18px;
    }
  }
  &__expanded-info {
    border-radius: 8px;
    background-color: #F8F8F8;
    width: 100%;
    box-sizing: border-box;
    padding: 32px;
    display: flex;
    flex-flow: column;
    row-gap: 32px;
    &--fields-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 305px);
      row-gap: 24px;
    }
    &--label {
      margin-bottom: 4px;
      font-size: 12px;
      line-height: 15px;
    }
    &--value {
      font-size: 18px;
      font-family: "InterSemiBold", sans-serif;
      line-height: 21px;
    }
  }
}
