.header_wrapper{
  height: 60px;
  background-color: #6429B3;
  padding: 0 40px;
  box-sizing: border-box;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  .logo{
    position: absolute;
    left: calc(50% - 54px);
    top: calc(50% - 8px);
    color: #ffffff;
  }
  .header_info{
    display: flex;
    align-items: center;
    .alarm_block{
      cursor: pointer;
      margin-right: 30px;
      transition: all 0.3s linear;
      img {
        min-width: 16px;
      }
      &:hover{
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
      }
    }
    .info{
      display: flex;
      align-items: center;
      position: relative;
      & > div{
        display: flex;
        flex-flow: column;
        align-items: flex-end;
        margin-right: 4px;
        >img{
          margin-left: 22px;
          cursor: pointer;
        }
        & > span{
          color: #FFFFFF;
          font-family: "InterSemiBold",sans-serif;
          margin-bottom: 2px;
          font-size: 14px;
          line-height: 17px;
        }
        & > p{
          opacity: 0.7;
          color: #FFFFFF;
          font-family: "InterSemiBold",sans-serif;
          font-size: 11px;
          line-height: 14px;
        }

      }
    }
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(1px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}


