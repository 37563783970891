.app-user-header {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px 0 40px;
  &__first-block {
    width: 70%;
  }
  &__last-block {
    width: 30%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  &__name {
    margin-bottom: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-family: "InterSemiBold", sans-serif;
    font-size: 24px;
    line-height: 29px;
  }
  &__platform-icon {
    margin-left: 10px;
    height: 20px;
    width: 20px;
  }
  &__info {
    color: #9B9B9B;
    font-size: 14px;
    line-height: 17px;
  }
  &__campaigns-count {
    margin-right: 40px;
    display: flex;
    align-items: center;
    &--icon {
      margin-right: 8px;
      width: 20px;
      height: 20px;
    }
    &--number {
      color: #324EA3;
      font-family: "InterSemiBold", sans-serif;
      font-size: 18px;
    }
  }
  &__balance {
    text-align: right;
    &--label {
      margin-bottom: 4px;
      color: #9B9B9B;
      font-size: 12px;
      line-height: 15px;
    }
    &--value {
      font-family: "InterSemiBold", sans-serif;
      font-size: 18px;
    }
  }
}