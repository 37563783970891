.company-payments {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  .payment-row {
    &__date {
      font-size: 14px;
      line-height: 18px;
    }
    &__type {
      max-width: 90%;
      font-size: 14px;
      line-height: 18px;
      b {
        font-weight: normal;
        font-family: "InterSemiBold", sans-serif;
      }
    }
    &__amount {
      font-family: "InterSemiBold", sans-serif;
      font-size: 14px;
      &.positive {
        color: #30837B;
      }
      &.negative {
        color: #E02020;
      }
    }
    &__note-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
    }
    &__note {
      max-width: 90%;
      font-size: 12px;
      line-height: 16px;
    }
    &__add-note {
      color: #F23EA6;
      font-family: "InterSemiBold", sans-serif;
      font-size: 12px;
      line-height: 16px;
    }
    &__edit-note {
      display: flex;
      width: 32px;
      height: 32px;
      margin-left: 10px;
    }
  }
}