.campaign-deliveries {
  width: 100%;
  box-sizing: border-box;
  .delivery-row {
    &__user {
      display: flex;
      flex-flow: column;
      &--name {
        margin-bottom: 2px;
        font-size: 14px;
        font-family: "InterSemiBold", sans-serif;
        line-height: 18px;
      }
      &--email {
        color: #9B9B9B;
        font-size: 12px;
        line-height: 16px;
      }
    }
    &__type {
      font-size: 14px;
      font-family: "InterSemiBold", sans-serif;
      line-height: 18px;
    }
  }
  .accordion-panel__details {
    .wrapper {
      display: flex;
      flex-flow: column;
      border-radius: 8px;
      background-color: #F8F8F8;
      padding: 32px!important;
      box-sizing: border-box;
      width: 100%;
    }
    h6 {
      font-size: 12px;
      line-height: 13px;
      margin-bottom: 4px;
      display: block;
    }
    p {
      font-size: 14px;
      font-family: "InterSemiBold", sans-serif;
      line-height: 18px;
    }
    .in_line_info {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 64px;
    }
  }
  .delivery-status {
    &__value {
      margin-bottom: 2px;
      color: #6429B3;
      font-size: 14px;
      font-family: "InterSemiBold", sans-serif;
      line-height: 18px;
    }
    &__updated {
      margin-top: 2px;
      color: #9B9B9B;
      font-size: 12px;
      line-height: 16px;
      font-family: "InterRegular", sans-serif;
      &--value {
        color: #656565;
      }
    }
  }
}