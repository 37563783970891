.table-container {
  width: 100%;
  .table-header {
    box-sizing: border-box;
    .table-row {
      display: flex;
      align-items: center;
      height: 44px;
      .row-item {
        display: flex;
        align-items: center;
        user-select: none;
        cursor: pointer;
        font-size: 12px;
        color: #9B9B9B;
        &__label {
          margin-right: 4px;
        }
        &__bold-text {
          font-family: "InterSemiBold", sans-serif;
          font-size: 14px;
          line-height: 18px;
        }
        &__usual-text {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
  .table-header {
    border-bottom: 1px solid #E2E5EB;
  }
  .table-header,
  .table-body {
    width: 100%;
    .table-row {
      width: 100%;
    }
  }
  .table-body {
    .row-item {
      display: grid;
      align-items: center;
      font-size: 14px;
    }
    .table-row {
      border-bottom: 1px solid #E2E5EB;
      width: 100%;
      display: flex;
      align-items: center;
      height: 68px;
      text-decoration: none;
    }
    &.with-expanded-row {
      .table-row {
        border-bottom: none;
      }
    }
  }
  .no-items {
    margin-top: 16px;
  }
}

.table-search {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  .select_wrapper {
    width: 228px;
    margin-right: 24px;
  }
}

.table-date-pickers {
  display: flex;
  align-items: center;
  column-gap: 24px;
}
