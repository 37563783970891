@font-face {
  font-family: "InterBold";
  src: url('../assets/fonts/InterBold.ttf') format("truetype");
}

@font-face {
  font-family: "InterLight";
  src: url('../assets/fonts/InterLight.ttf') format("truetype");
}

@font-face {
  font-family: "InterMedium";
  src: url('../assets/fonts/InterMedium.ttf') format("truetype");
}

@font-face {
  font-family: "InterRegular";
  src: url('../assets/fonts/InterRegular.ttf') format("truetype");
}

@font-face {
  font-family: "InterSemiBold";
  src: url('../assets/fonts/InterSemiBold.ttf') format("truetype");
}

@font-face {
  font-family: "InterExtraBold";
  src: url('../assets/fonts/InterExtraBold.ttf') format("truetype");
}


@import "./reset";
@import "./base";
@import "./variables";