.campaigns-stats {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  .campaign-stats-row {
    &__date {
      font-size: 14px;
      line-height: 18px;
    }
    &__name {
      font-family: "InterSemiBold", sans-serif;
      font-size: 14px;
      line-height: 18px;
      max-width: 90%;
    }
    &__participants {
      color: #9B9B9B;
      font-size: 14px;
      line-height: 18px;
      b {
        color: #383838;
        font-family: "InterSemiBold", sans-serif;
        font-weight: normal;
      }
    }
    &__status {
      color: #9B9B9B;
      font-family: "InterMedium", sans-serif;
      font-size: 14px;
      line-height: 18px;
      &.active {
        color: #30837B;
      }
      &.finalizing {
        color: #324EA3;
      }
    }
    &__published-at {
      margin-top: 2px;
      color: #656565;
      font-size: 12px;
      line-height: 16px;
      span {
        color: #9B9B9B;
      }
    }
  }
}

.accordion-cost-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  width: 100%;
  border-radius: 8px;
  background-color: #F8F8F8;
  padding: 32px;
  &__separator {
    width: 100%;
    height: 1px;
    background-color: #E2E5EB;
    margin: 32px 0;
  }
}

.cost_block {
  display: flex;
  flex-flow: column;
  .inactive {
    color: #8E8E8E!important;
  }
  .cost_marked_block {
    display: flex;
    margin-bottom: 32px;
    & > div {
      width: 33%;
      & > p {
        display: block;
        margin-bottom: 4px;
        font-size: 12px;
        line-height: 15px;
      }
      div {
        font-family: "InterSemiBold", sans-serif;
        font-size: 18px;
        line-height: 21px;
        &.used {
          color: #8E8E8E;
          font-size: 12px;
          margin-top: 4px;
          line-height: 15px;
        }
        &.value_with_button {
          display: flex;
          .default_button_wrapper {
            margin-left: 64px;
            .default_button {
              margin: 0!important;
            }
          }
        }
      }
    }
  }
  .cost_block_info {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    .total_estimated_cost {
      margin-bottom: 32px;
      &.inactive {
        p, span {
          color: #9B9B9B!important;
        }
      }
      & > p {
        display: block;
        margin-bottom: 4px;
        font-size: 12px;
        line-height: 15px;
      }
      & > div {
        font-family: "InterSemiBold", sans-serif;
        font-size: 48px;
        line-height: 59px;
      }
    }
    .split_info {
      width: 100%;
      display: flex;
      &.mb-32 {
        margin-bottom: 32px;
      }
      &.inactive {
        p, span {
          color: #9B9B9B!important;
        }
      }
      & > div {
        width: 33%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        & > p {
          display: block;
          margin-bottom: 4px;
          font-size: 12px;
          line-height: 15px;
        }
        & > div {
          font-family: "InterSemiBold", sans-serif;
          font-size: 18px;
          line-height: 21px;
          color: #9B9B9B;
          & > span {
            color: #383838;
          }
        }
      }
    }
  }
}
