.datepicker{
  padding: 0!important;
  position: relative;
  &__adornment {
    position: absolute;
    left: 15px;
    top: 11px;
    color: #9B9B9B;
    font-size: 12px;
    line-height: 15px;
  }
  div{
    padding-right:0!important;
  }
  .Mui-disabled {
    cursor: not-allowed!important;
  }
  >div{
    margin: 0;
    >div{
      position: relative;

      &:before{
        display: none;
      }
      &:after{
        display: none;
      }
      >div{
        position: absolute;
        right: -4px;
        width: 16px;
        height: 16px;
        top: calc(50% - 8px);
        >button{
          padding: 0;
          width: 16px;
          height: 16px;
          right: 16px;
          background: transparent!important;
          &:before{
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            background: url("../../../assets/image/calendar.svg");
            width: 16px;
            height: 16px;
          }
          span{
            display: none;
          }
        }
      }
      input{
        background-color: #fff;
        border: 1px solid #CECECE;
        border-radius: 4px;
        color: #383838;
        font-size: 12px;
        line-height: 15px;
        padding: 0 15px 0 61px!important;
        height: 37px;
        width: 180px !important;
        box-sizing: border-box;
        font-family: "InterSemiBold",sans-serif!important;
        &::placeholder {
          opacity: 1!important;
        }
      }
    }
  }
}

.MuiPickersModal-dialogRoot {
  .MuiPickersDatePickerRoot-toolbar {
    background-color: #F23EA6!important;
  }

  .MuiPickersDay-daySelected {
    background-color: #F23EA6!important;
  }

  .MuiButton-textPrimary {
    color: #F23EA6!important;
  }
}
.MuiFormHelperText-root.Mui-error{
  display: none;
  position: absolute;
  bottom: -20px;
  color: #D0021B!important;
  font-size: 10px;
  margin: 0;
}