.purchases {

  .table-search {
    .search {
      input {
        width: 240px;
      }
    }

    .select_wrapper {
      width: 178px;
    }

    .check_field_wrapper label > :last-child {
      font-family: "InterRegular", sans-serif !important;
    }
  }

  .purchases-row {
    &__date {
      font-size: 14px;
      line-height: 18px;
    }

    &__name {
      font-family: "InterMedium", sans-serif;
      font-size: 14px;
      line-height: 18px;
      max-width: 90%;
    }

    &__user-name {
      font-size: 14px;
      font-family: "InterSemiBold", sans-serif;
      line-height: 20px;
    }

    &__user-email {
      color: #9B9B9B;
      font-size: 12px;
      line-height: 16px;
    }

    &__status {
      color: #6429B3;
      font-family: "InterSemiBold", sans-serif;
      font-size: 14px;
      line-height: 18px;
    }

    &__updated-at {
      color: #9B9B9B;
      margin-top: 2px;
      font-size: 12px;
      line-height: 16px;

      span {
        color: #656565;
      }
    }
  }
}

.purchase-details {
  border-radius: 8px;
  background-color: #F8F8F8;
  padding: 32px;
  width: 100%;
  box-sizing: border-box;

  &__separator {
    height: 1px;
    width: 100%;
    background-color: #E2E5EB;
    margin: 32px 0;
  }

  &__product-info {
    display: grid;
    grid-template-columns: auto 80px 113px 88px 1fr;
    column-gap: 64px;
    row-gap: 24px;
  }

  &__delivery-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 64px;
    row-gap: 24px;
  }

  &__label {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 4px;
  }

  &__value {
    font-size: 14px;
    font-family: "InterSemiBold", sans-serif;
    line-height: 18px;
  }

  &__states {
    display: flex;
    align-items: center;
    column-gap: 64px;
    row-gap: 24px;
    flex-flow: wrap;
  }

  &__state {
    display: flex;
    column-gap: 12px;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      right: -40px;
      top: 0;
      background: url("../../assets/image/state-arrow.svg");
      width: 16px;
      height: 16px;
    }

    &:last-of-type {
      &::after {
        display: none;
      }
    }

    img {
      min-width: 16px;
      width: 16px;
      height: 16px;
    }

    &--info {
      display: flex;
      flex-flow: column;
      column-gap: 4px;
    }
  }

  .note {

    &__label {
      color: #383838!important;
      font-family: "InterSemiBold", sans-serif;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 12px;
    }

    textarea {
      margin-bottom: 24px;
      font-family: "InterRegular", sans-serif;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 15px;
      width: 100%;
      height: 54px;
      border: 1px solid #CECECE;
      border-radius: 4px;
      padding: 12px 16px;
      box-sizing: border-box;
      resize: none;
      color: #000000;
      background: transparent;
    }

    .default_button.confirm {
      width: 112px;
      height: 35px;
      font-size: 12px;
      font-family: "InterBold", sans-serif;
      line-height: 15px;
    }
  }
}
