.company {
  z-index: 2;
  display: flex;
  flex-flow: column;
  width: 100%;
  box-sizing: border-box;
  padding: 64px;
  &__wrapper {
    min-width: 910px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 7px -3px rgba(88,88,88,0.2), 0 12px 19px 1px rgba(92,92,92,0.06), 0 5px 23px 4px rgba(89,89,89,0.05);
    padding: 40px;
    margin-bottom: 30px;
    &--title {
      font-size: 18px;
      font-family: "InterSemiBold", sans-serif;
      line-height: 21px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &--add-btn {
      color: #F23EA6;
      font-family: "InterSemiBold", sans-serif;
      font-size: 14px;
      line-height: 17px;
    }
  }
  &__tabs {
    width: 100%;
    margin-top: 16px;
    border-bottom: 1px solid #E9E9E9;
    .tabs_wrapper {
      width: 100%;
      margin-bottom: -1px;
    }
  }
}