.default_button_wrapper {
  .default_button {
    text-align: center;
    display: inline-flex;
    font-family: "InterBold", sans-serif;
    font-size: 14px;
    white-space: nowrap;
    box-shadow: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    img{
      margin-left: 15px;
    }
    &:disabled {
      opacity: 0.5;
      box-shadow: none;
    }
    &.auth {
      height: 40px;
      min-width: 180px;
      border-radius: 20.5px;
      background-color: #F23EA6;
      color: #ffffff;
    }
    &.button_86 {
      height: 27px;
      min-width: 86px;
      background: #FFFFFF;
      border-radius: 13.5px;
      font-family: "InterMedium", sans-serif;
      font-size: 10px;
      text-transform: none;
      &.approve, &.unsuspend, &.add_credits {
        border: 1px solid #F23EA6;
        color: #F23EA6;
      }
      &.reject, &.suspend {
        border: 1px solid #CECECE;
        color: #656565;
      }
    }
    &.confirm {
      height: 40px;
      width: 153px;
      border: 1px solid #F23EA6;
      border-radius: 20.5px;
      background-color: #F23EA6;
      letter-spacing: 1px;
      color: #ffffff;
    }
    &.cancel_btn {
      height: 40px;
      min-width: 153px;
      border: 1px solid  #F5F5F5;
      background: #F5F5F5;
      border-radius: 20.5px;
      letter-spacing: 1px;
      color: #111111;
    }
  }
}